import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { P } from '@ebinion/zeke-design-system'

import { List, Li } from './List'

export default function Markdown(props) {
  const { align, children, onDarkBG } = props

  const components = {
    p: memo(componentProps => (
      <P align={align} color={onDarkBG ? 'knockout' : 'normal'}>
        {componentProps.children}
      </P>
    )),
    ul: memo(componentProps => <List>{componentProps.children}</List>),
    ol: memo(componentProps => <List as="ol">{componentProps.children}</List>),
    li: memo(componentProps => <Li>{componentProps.children}</Li>),
  }

  return <ReactMarkdown components={components}>{children}</ReactMarkdown>
}

Markdown.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  children: PropTypes.string.isRequired,
  onDarkBG: PropTypes.bool,
}

Markdown.defaultProps = {
  align: 'left',
  onDarkBG: false,
}
