import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { get } from 'lodash'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import { Block, colorTokens, H1, measurementTokens, Rows, Text } from '@ebinion/zeke-design-system'

import CaseStudies from '../components/CaseStudies'
import contentfulRenderOptions from '../components/contentfulRenderOptions'
// import Icon from '../components/Icon'
import Layout from '../components/Layout'
import Markdown from '../components/Markdown'

const Header = styled.header`
  --l-bg: ${colorTokens.backgrounds.block.white};
  background: var(--l-bg);
  overflow: hidden;
  position: relative;

  ::before {
    background-repeat: no-repeat;
    background-size: cover;
    ${props =>
      props.imageURL &&
      css`
        background-image: url('${props.imageURL}');
      `}
    content: '';
    filter: blur(30px);
    height: 100%;
    left: 0;
    overflow: hidden;
    opacity: 0.2;
    position: absolute;
    transform: translateY(-10%);
    top: 0;
    width: 100%;
    z-index: 0;
  }

  ::after {
    background: var(--l-bg);
    bottom: 0;
    content: '';
    height: 18%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  @media (min-width: ${measurementTokens.breakpoints.horizontal.m}) {
    ::after {
      height: 25%;
    }
  }
`

const HeaderContent = styled.div`
  padding-top: 140px;
  position: relative;
  z-index: 3;

  @media (min-width: ${measurementTokens.breakpoints.horizontal.m}) {
    padding-top: 220px;
  }

  @media (min-width: ${measurementTokens.breakpoints.horizontal.l}) {
    padding-top: 260px;
  }
`

const StyledAttribute = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: start;
`

// Depreacted at the moment. Will remove in future versions
// const StyledAttributes = styled.div`
//   display: grid;
//   gap: ${measurementTokens.componentPadding.m};
//   justify-content: start;
//   align-items: start;

//   @media (min-width: ${measurementTokens.breakpoints.horizontal.m}) {
//     grid-template-columns: 1fr 1fr;
//     padding-bottom: ${measurementTokens.componentMargin.m};
//   }

//   @media (min-width: ${measurementTokens.breakpoints.horizontal.l}) {
//     align-items: center;
//     display: flex;
//     gap: ${measurementTokens.componentPadding.xl};
//     justify-content: center;
//   }
// `

function Attribute(props) {
  const { icon, value } = props
  return (
    <StyledAttribute>
      {icon}
      <Text noMargin size="s">
        {value}
      </Text>
    </StyledAttribute>
  )
}
Attribute.propTypes = {
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
}

function Entry(props) {
  const {
    data: { entry },
    pageContext: { id: currentID },
  } = props

  const previewImage = get(entry, 'previewImage.localFile.childImageSharp.gatsbyImageData', null)
  const leadImage = get(entry, 'leadImage.localFile.childImageSharp.gatsbyImageData', null)

  const renderHeader = () => (
    <Header imageURL={previewImage ? previewImage.images.fallback.src : undefined}>
      <HeaderContent>
        <Block isCentered constrain="text">
          <Rows spacing="xl">
            <div>
              {entry.organization && (
                <Text bold noMargin align="center">
                  {entry.organization}
                </Text>
              )}
              <H1 align="center" noMargin>
                {entry.title}
              </H1>
            </div>
            {leadImage && (
              <GatsbyImage
                image={leadImage}
                alt={
                  entry.leadImage.description ? entry.leadImage.description : entry.leadImage.title
                }
              />
            )}
          </Rows>
        </Block>
      </HeaderContent>
    </Header>
  )

  const renderSummary = () => {
    const { intro, roles } = entry

    return (
      <Block constrain="text" isCentered>
        {/* roles && (
          <Attribute
            icon={<Icon icon="badge" color="gold" />}
            label="Project Roles"
            value={roles.join(' / ')}
            viewBox="0 0 384 512"
          />
        ) */}

        {intro && <Markdown>{intro.childMarkdownRemark.rawMarkdownBody}</Markdown>}
      </Block>
    )
  }

  return (
    <Layout
      hasFloatedHeader
      footerChildren={<CaseStudies currentID={currentID} />}
      title={entry.title}
    >
      <Helmet>
        {entry.hasCodepen && (
          <script async src="https://cpwebassets.codepen.io/assets/embed/ei.js" />
        )}
      </Helmet>
      {renderHeader()}
      <Block color="white" padding="none" paddingBottom="var(--component-padding-xl)">
        {renderSummary()}
        {entry.body && renderRichText(entry.body, contentfulRenderOptions)}
      </Block>
    </Layout>
  )
}

Entry.propTypes = {
  // The data from GraphQl is super complicated
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default Entry

export const pageQuery = graphql`
  query ($id: String!) {
    entry: contentfulPortfolioEntry(id: { eq: $id }) {
      contentful_id
      hasCodepen
      organization
      roles
      slug
      title
      type
      year
      intro {
        childMarkdownRemark {
          rawMarkdownBody
        }
        sys {
          type
        }
      }
      previewImage {
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        sys {
          type
        }
      }
      leadImage {
        title
        sys {
          type
        }
        description
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body {
        raw
        references {
          __typename
          ... on ContentfulImageFigure {
            contentful_id
            imagePosition
            imageAlt
            content {
              raw
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              description
              title
              sys {
                type
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulGallery {
            sys {
              contentType {
                sys {
                  id
                }
              }
              type
            }
            contentful_id
            title
            items {
              contentful_id
              node_locale
              title
              maxWidth
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              image {
                contentful_id
                title
                description
                localFile {
                  childImageSharp {
                    fullImage: gatsbyImageData
                    thumbnail: gatsbyImageData(
                      height: 600
                      width: 600
                      transformOptions: { cropFocus: NORTH, fit: COVER }
                    )
                  }
                }
                sys {
                  type
                }
              }
            }
          }
          ... on ContentfulEmbedFigure {
            id
            isIframe
            embedPosition
            title
            embedCode {
              childMarkdownRemark {
                rawMarkdownBody
              }
              sys {
                type
              }
            }
            content {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
              type
            }
            contentful_id
          }
          ... on ContentfulSection {
            contentful_id
            appearance
            superTitle
            title
            intro {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            body {
              raw
              references {
                __typename
                ... on ContentfulImageFigure {
                  contentful_id
                  imagePosition
                  imageAlt
                  content {
                    raw
                  }
                  image {
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    description
                    title
                    sys {
                      type
                    }
                    file {
                      contentType
                    }
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulGallery {
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                    type
                  }
                  contentful_id
                  title
                  items {
                    contentful_id
                    node_locale
                    title
                    maxWidth
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    image {
                      contentful_id
                      title
                      description
                      localFile {
                        childImageSharp {
                          fullImage: gatsbyImageData
                          thumbnail: gatsbyImageData(
                            height: 600
                            width: 600
                            transformOptions: { cropFocus: NORTH, fit: COVER }
                          )
                        }
                      }
                      sys {
                        type
                      }
                    }
                  }
                }
                ... on ContentfulEmbedFigure {
                  contentful_id
                  id
                  isIframe
                  embedPosition
                  title
                  embedCode {
                    childMarkdownRemark {
                      rawMarkdownBody
                    }
                    sys {
                      type
                    }
                  }
                  content {
                    raw
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                    type
                  }
                }
                ... on ContentfulAsset {
                  contentful_id
                  description
                  id
                  title
                  sys {
                    type
                  }
                  file {
                    contentType
                    url
                  }
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
