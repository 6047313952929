// There's a lot of potential for circular dependencies, be careful
/* eslint-disable no-use-before-define */
import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  A,
  Block,
  Embed,
  Figure,
  Gallery,
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Img,
  Section,
  Strong,
} from '@ebinion/zeke-design-system'

import Markdown from './Markdown'
import { List, Li } from './List'

const CONSTANTS = {
  LOCALE: 'en-US',
  SECTION: 'ContentfulSection',
  IMAGE_FIGURE: 'ContentfulImageFigure',
  EMBED_FIGURE: 'ContentfulEmbedFigure',
  GALLERY: 'ContentfulGallery',
}

const isMediaTypePdf = contentType => contentType === 'application/pdf'

const getContentType = fileNode => get(fileNode, 'data.target.file.contentType', null)

const getGatsbyImageData = node =>
  get(node, 'data.target.localFile.childImageSharp.gatsbyImageData', null)

function EmbedBlock({ children }) {
  return <Embed>{children}</Embed>
}
EmbedBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

function FigureBlock({ children, figure, figureIsOnRight }) {
  return (
    <Figure figurePosition={figureIsOnRight ? 'right' : 'left'} figure={figure}>
      {children}
    </Figure>
  )
}
FigureBlock.propTypes = {
  children: PropTypes.node.isRequired,
  figure: PropTypes.node.isRequired,
  figureIsOnRight: PropTypes.bool.isRequired,
}

function BlockWrapper({ children, noPad }) {
  return (
    <Block
      constrain="site"
      isCentered
      paddingBottom={noPad ? '0' : undefined}
      paddingTop={noPad ? '0' : undefined}
    >
      {children}
    </Block>
  )
}
BlockWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  noPad: PropTypes.bool,
}
BlockWrapper.defaultProps = {
  noPad: false,
}
// eslint-disable-next-line no-underscore-dangle
const getBlockType = node => node.data.target.__typename

const renderEmbedFigureBlock = node => {
  const { embedPosition, content, isIframe } = node.data.target
  const embedCode = node.data.target.embedCode.childMarkdownRemark.rawMarkdownBody

  // Safe to do since data is coming from server
  /* eslint-disable react/no-danger */
  const getEmbed = () => {
    if (isIframe) {
      return (
        <Embed>
          <div dangerouslySetInnerHTML={{ __html: embedCode }} />
        </Embed>
      )
    }

    return <div dangerouslySetInnerHTML={{ __html: embedCode }} />
  }
  /* eslint-enable react/no-danger */

  if (embedPosition !== 'center') {
    return (
      <FigureBlock figureIsOnRight={embedPosition === 'right'} figure={getEmbed()}>
        {content &&
          renderRichText(
            content,
            sharedOptions({
              isCaption: true,
              isDark: false,
              hasCenteredLayout: false,
            })
          )}
      </FigureBlock>
    )
  }

  return (
    <figure>
      <Block constrain="site" isCentered>
        {getEmbed()}
      </Block>
      <figcaption>
        {content && renderRichText(content, sharedOptions({ isDark: false }))}
      </figcaption>
    </figure>
  )
}

// TODO: improve support for gallry thumbnails
const renderGallery = node => {
  const galleryItems = node.data.target.items.map(item => {
    const imageData = get(item, 'image.localFile.childImageSharp.fullImage', null)
    const image = <GatsbyImage image={imageData} alt={item.image.description || item.image.title} />

    return { image, title: item.image.title }
  })

  return <Gallery items={galleryItems} portalTarget={() => document.querySelector('#portal')} />
}

const renderImageFigureBlock = node => {
  const { imageAlt, imagePosition, image, content } = node.data.target
  const imageData = get(image, 'localFile.childImageSharp.gatsbyImageData', null)
  const imageURL = get(image, 'localFile.publicURL', null)

  return (
    <FigureBlock
      figureIsOnRight={imagePosition === 'right'}
      figure={
        imageData ? (
          <GatsbyImage image={imageData} alt={imageAlt} shadowed />
        ) : (
          <Img src={imageURL} alt={imageAlt} />
        )
      }
    >
      {content &&
        renderRichText(
          content,
          sharedOptions({
            isCaption: true,
            isDark: false,
            hasCenteredLayout: false,
          })
        )}
    </FigureBlock>
  )
}

const renderSectionBlock = node => {
  // Underscore comes from API response
  /* eslint-disable camelcase */
  const { appearance, body, contentful_id, superTitle, title } = node.data.target
  const intro = node.data.target.intro && node.data.target.intro.childMarkdownRemark.rawMarkdownBody

  const isDark = () => appearance === 'dark'

  return (
    <Section
      isDark={isDark()}
      key={contentful_id}
      lead={intro && <Markdown onDarkBG={isDark()}>{intro}</Markdown>}
      padding="m"
      superTitle={superTitle}
      title={title}
    >
      <div>{renderRichText(body, sectionOptions({ isDark: isDark() }))}</div>
    </Section>
  )
  /* eslint-enable camelcase */
}

const sharedOptions = (options = { isCaption: false, isDark: false, hasCenteredLayout: true }) => {
  const defaultOptions = {
    isCaption: false,
    isDark: false,
    hasCenteredLayout: true,
  }
  const combinedOptions = { ...defaultOptions, ...options }

  let textColor = combinedOptions.isDark ? 'knockout' : 'normal'
  textColor = combinedOptions.isCaption ? 'light' : textColor

  return {
    renderMark: {
      [MARKS.BOLD]: text => (
        <Strong color={textColor === 'knockout' ? 'knockout' : 'bold'}>{text}</Strong>
      ),
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const {
          data: { uri },
        } = node

        return <A to={uri}>{children}</A>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <P
          color={textColor}
          size={combinedOptions.isCaption ? 's' : 'm'}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </P>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <H1
          as="h2"
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <H2
          as="h3"
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <H3
          as="h4"
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <H4
          as="h5"
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <H5
          as="h6"
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <H6
          color={textColor}
          isCentered={combinedOptions.hasCenteredLayout}
          constrain={combinedOptions.hasCenteredLayout}
        >
          {children}
        </H6>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <List as="ol" isCentered>
          {children}
        </List>
      ),
      [BLOCKS.UL_LIST]: (node, children) => <List isCentered>{children}</List>,
      [BLOCKS.LIST_ITEM]: (node, children) => <Li>{children}</Li>,
    },
  }
}

const sectionOptions = ({ isDark }) => {
  const options = sharedOptions({ isDark })

  return {
    renderMark: { ...options.renderMark },
    renderNode: {
      ...options.renderNode,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fileType = getContentType(node)

        const {
          data: {
            target: {
              title: altText,
              file: { url },
            },
          },
        } = node

        if (isMediaTypePdf(fileType)) {
          return (
            <BlockWrapper>
              <EmbedBlock>
                <object type="application/pdf" data={url} width="1024" height="525" title={altText}>
                  {altText}
                </object>
              </EmbedBlock>
            </BlockWrapper>
          )
        }

        if (getGatsbyImageData(node)) {
          return (
            <Block isInset>
              <GatsbyImage image={getGatsbyImageData(node)} alt={altText} shadowed={!isDark} />
            </Block>
          )
        }

        return <div />
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        switch (getBlockType(node)) {
          case CONSTANTS.IMAGE_FIGURE:
            return (
              <Block padding="l" constrain="site" isCentered>
                {renderImageFigureBlock(node)}
              </Block>
            )
          case CONSTANTS.EMBED_FIGURE:
            return <BlockWrapper>{renderEmbedFigureBlock(node)}</BlockWrapper>
          case CONSTANTS.GALLERY:
            return <BlockWrapper>{renderGallery(node)}</BlockWrapper>
          default:
            return <div />
        }
      },
    },
  }
}

const options = {
  renderMark: { ...sharedOptions().renderMark },
  renderNode: {
    ...sharedOptions().renderNode,
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      switch (getBlockType(node)) {
        case CONSTANTS.EMBED_FIGURE:
          return <BlockWrapper>{renderEmbedFigureBlock(node)}</BlockWrapper>
        case CONSTANTS.GALLERY:
          return <BlockWrapper>{renderGallery(node)}</BlockWrapper>
        case CONSTANTS.IMAGE_FIGURE:
          return <BlockWrapper>{renderImageFigureBlock(node)}</BlockWrapper>
        case CONSTANTS.SECTION:
          return renderSectionBlock(node)
        default:
          return <div />
      }
    },
  },
}

export default options
