import styled, { css } from 'styled-components'
import { measurementTokens, textTokens } from '@ebinion/zeke-design-system'

export const List = styled.ul`
  margin: 1rem 0;
  padding: 0;

  ${props =>
    props.isCentered &&
    css`
      margin: 1rem auto;
      padding: 0 var(--site-padding);
      max-width: ${measurementTokens.maxTextWidth};
    `}
`

export const Li = styled.li`
  line-height: ${textTokens.sizes.m.lineHeightNormal};
  margin: 0.5rem 0;
  margin-left: var(--component-padding-xl);
  padding-left: var(--component-padding);

  > p {
    padding-left: 0;
    padding-right: 0;
  }
`
